<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" md="6" class="d-none d-lg-flex p-0">
        <SplashScreen />
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-overlay :show="validationLoading">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              <div v-if="!validationLoading">
                <span v-if="!wrongUrl">{{ $t('common.thanks') }}</span>
                <span v-else>{{ $t('common.warning') }}</span>
              </div>
            </b-card-title>
            <b-card-text class="mt-2">
              <div v-if="!validationLoading">
                <p v-if="!wrongUrl">{{ $t('register.success_text') }}</p>
                <p v-else-if="wrongUrl && errorMessage">{{ $t(errorMessage) }}</p>
                <p v-else>{{ $t('register.wrong_link_text') }}</p>
              </div>
            </b-card-text>
          </b-overlay>
          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact' }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SplashScreen from '@/views/base/SplashScreen.vue'
import { postRegisterValidateRequest } from '@/request/authApi/requests/apiRequests'

export default {
  name: 'RegisterValidate',
  components: {
    SplashScreen,
  },

  data() {
    return {
      validationLoading: true,
      wrongUrl: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
  },
  mounted() {
    this.validationLoading = true
    if (this.$route.query.token) {
      this.registerValidation(this.$route.query.token)
    } else {
      this.wrongUrl = true
      this.validationLoading = false
    }
  },

  methods: {
    registerValidation(token) {
      postRegisterValidateRequest({ token })
        .catch(error => {
          if (error.response) {
            this.wrongUrl = true
            this.errorMessage = error.response.data.message
          }
        })
        .finally(() => {
          this.validationLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
